var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('Banner',{attrs:{"homepage":true,"title":"HomeBanner","connectedTo":"page"}}),(_vm.loading)?_c('v-container',{staticClass:"container-custom padtb"},_vm._l((3),function(i){return _c('div',{key:i,staticClass:"mrb"},[_c('v-row',[_c('v-col',{key:_vm.n,attrs:{"md":"4"}},[_c('v-skeleton-loader',{attrs:{"max-width":"420","type":"image","height":"30"}})],1)],1),_c('v-row',[_vm._l((8),function(m){return _c('v-col',{key:m,attrs:{"md":"3"}},[_c('v-skeleton-loader',{attrs:{"max-width":"420","type":"image","height":"200"}})],1)})],2)],1)}),0):_vm._e(),_c('v-container',{staticClass:"container-custom"},[_c('v-row',[_c('v-col',{attrs:{"md":"9"}},[_c('v-row',_vm._l((_vm.allHomeJob.results),function(data,index){return _c('v-col',{key:index,staticClass:"subscription-section",attrs:{"md":"12"}},[(data.home.company.length > 0)?[_c('div',[_c('h6',{staticClass:"subscriptiontitle"},[_vm._v(_vm._s(data.home.type))])]),_c('v-row',{staticClass:"sectionsjob"},[_c('JobBox',{attrs:{"jobContent":data.home.company,"boxtype":data.home.type}})],1)]:_vm._e()],2)}),1)],1),(_vm.allPosts.results)?_c('v-col',{staticClass:"adssection"},[_c('div',{staticClass:"sortbycat"},[_c('div',{staticClass:"category-view"},[_c('h6',{staticClass:"subscriptiontitle1"},[_vm._v("Jobs By Category")]),_c('button',[_c('router-link',{attrs:{"to":"/category"}},[_vm._v(" View All ")])],1)]),(!_vm.loading)?_c('ul',_vm._l((_vm.allHomeCategoriesJob.results),function(data,index){return _c('li',{key:index},[_c('router-link',{attrs:{"to":`/category/${data.slug}`}},[_vm._v(" "+_vm._s(data.title)+" "),_c('span',[_vm._v("("+_vm._s(data.jobs)+")")])])],1)}),0):_vm._e(),_c('h6',{staticClass:"subscriptiontitle1"},[_vm._v("Courses By Neco")]),(!_vm.loading)?_c('div',{staticClass:"course-container"},[_c('slick',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.allTrainings.results &&
                _vm.allTrainings.results.list &&
                _vm.allTrainings.results.list.length > 0
              ),expression:"\n                allTrainings.results &&\n                allTrainings.results.list &&\n                allTrainings.results.list.length > 0\n              "}],ref:"slick",attrs:{"options":_vm.slickOptions}},_vm._l((_vm.allTrainings.results.list),function(data,index){return _c('div',{key:index,staticClass:"mb-5",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push(`/course/${data.slug}`)}}},[_c('div',{staticClass:"sider-part"},[_c('img',{staticStyle:{"height":"200px","object-fit":"cover","width":"100%"},attrs:{"src":'https://app.necojobs.com.np' + data.imageUrl,"alt":""}}),_c('div',{staticClass:"training-title"},[_c('h4',[_vm._v(_vm._s(data.trainingTitle))])])])])}),0)],1):_vm._e(),_c('h6',{staticClass:"subscriptiontitle1 blogs"},[_vm._v("Blogs")]),(!_vm.loading)?_c('div',{staticClass:"blog-container"},_vm._l((_vm.blogdata.slice(0, 6)),function(data,index){return _c('div',{key:index,staticClass:"blog-item",on:{"click":function($event){return _vm.$router.push(`/singleBlog/${data.slug}`)}}},[(data.imageUrl)?[_c('div',{staticClass:"blog-card"},[_c('div',{staticClass:"blog-image"},[_c('img',{attrs:{"src":'https://app.necojobs.com.np' + data.imageUrl,"alt":data.category}})]),_c('div',{staticClass:"blog-info"},[_c('h3',{staticClass:"blog-category"},[_vm._v(_vm._s(data.title))])])])]:_vm._e()],2)}),0):_vm._e()]),_c('div',{staticClass:"ads-container"},_vm._l((_vm.adsData),function(data,index){return _c('span',{key:index + 'ad',on:{"click":function($event){return _vm.popupSelected(
                'https://app.necojobs.com.np' + data.imageUrl,
                data.link
              )}}},[_c('img',{attrs:{"src":'https://app.necojobs.com.np' + data.imageUrl,"alt":""}})])}),0),(_vm.popupStatus)?_c('div',{staticClass:"popUpImageClose",on:{"click":function($event){return _vm.popupSelected()}}}):_vm._e(),(_vm.popupStatus)?_c('div',{staticClass:"popUpImage"},[_c('div',[_c('header',[_c('v-icon',{on:{"click":function($event){return _vm.popupSelected()}}},[_vm._v(" mdi-close ")])],1),_c('img',{attrs:{"src":_vm.selectedAd.image,"alt":""}}),_c('a',{attrs:{"href":_vm.selectedAd.link,"target":"_blank"}},[_vm._v(" Browse ")])])]):_vm._e()]):_vm._e()],1)],1),_c('FindJob')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }