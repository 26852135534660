<template>
  <section>
    <v-row>
      <v-col md="12">
        <div class="fullwidth mainbox">
          <div class="boxinside">
            <v-form
              ref="companyBasicForm"
              v-model="valid"
              lazy-validation
              class="form-section"
            >
              <div class="fullwidth">
                <label class="mr-btm">Full Name</label>
                <v-text-field
                  v-model="fullname"
                  outlined
                  label="Full Name"
                  class="mr-btm-minus"
                  :rules="fullnameRule"
                ></v-text-field>
              </div>
              <div class="fullwidth">
                <label class="mr-btm">Contact Number</label>
                <v-text-field
                  v-model="phone"
                  outlined
                  label="Contact Number"
                  class="mr-btm-minus"
                  :rules="phoneRule"
                ></v-text-field>
              </div>
              <div class="fullwidth">
                <label class="mr-btm">Email ID</label>
                <v-text-field
                  v-model="email"
                  outlined
                  label="Email ID"
                  class="mr-btm-minus"
                  :rules="emailRule"
                ></v-text-field>
              </div>

              <div class="fullwidth" v-if="allCategory.results">
                <label class="mr-btm">Category</label>
                <v-select
                  v-model="category"
                  :items="allCategory.results.list"
                  item-text="title"
                  item-value="categoryHandle"
                  label="Select Category"
                  outlined
                  :rules="categoryRule"
                  @change="selectCat(category)"
                ></v-select>
              </div>

              <div class="fullwidth" v-if="subcategories.length > 0">
                <label class="mr-btm">Subcategory</label>
                <v-select
                  v-model="subcategory"
                  :items="subcategories"
                  item-text="title"
                  item-value="title"
                  label="Select Subcategory"
                  outlined
                  multiple
                  chips
                  :rules="subcategoryRule"
                ></v-select>
              </div>
              <div class="fullwidth">
                <label class="mr-btm">Experience Level</label>
                <v-select
                  v-model="experiencelevel"
                  :items="experiencelevels"
                  label="Select Experience Level"
                  outlined
                  :rules="experiencelevelRule"
                ></v-select>
              </div>
              <div class="fullwidth">
                <label class="mr-btm">Upload Your CV</label>
                <v-file-input
                  truncate-length="15"
                  label="Upload your CV"
                  v-model="filedata"
                  small-chips
                  accept="application/pdf,application/docx,application/doc"
                  outlined
                ></v-file-input>
              </div>

              <div class="fullwidth">
                <v-btn
                  class="addmore"
                  v-if="!allLoadingResume"
                  @click="validate"
                  >Save</v-btn
                >
                <v-btn class="addmore" v-if="allLoadingResume" disabled>
                  <v-progress-circular indeterminate></v-progress-circular>
                </v-btn>
              </div>

              <v-snackbar
                v-if="allUploadCv.message != undefined"
                v-model="snackbar"
              >
                <span>Your CV has been uploaded successfully thanks!</span>
              </v-snackbar>
            </v-form>
          </div>
        </div>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AddCv",
  data: () => ({
    snackbar: false,
    valid: true,
    fullname: "",
    email: "",
    phone: "",
    category: "",
    subcategory: "",
    experiencelevel: "",
    filedata: null,
    subcategories: [],
    experiencelevels: ["Entry Level", "Mid Level", "Senior Level", "Top Level"],
    fullnameRule: [(v) => !!v || "Fullname is required"],
    emailRule: [(v) => !!v || "Email is required"],
    phoneRule: [(v) => !!v || "Phone Number is required"],
    categoryRule: [(v) => !!v || "Category is required"],
    subcategoryRule: [(v) => !!v || "Subcategory is required"],
    experiencelevelRule: [(v) => !!v || "Experirce leve is required"],
    uploadCVRule: [(v) => !!v || "PDF file is required"],
  }),
  methods: {
    ...mapActions(["fetchCategory", "fetchSubCategory", "uploadCV"]),
    selectCat(cat) {
      this.subcategories = this.allSubcategory.results.list.filter((ele) => {
        return ele.categoryHandle == cat;
      });
    },
    validate() {
      if (this.$refs.companyBasicForm.validate()) {
        let fd = new FormData();
        fd.append("fullname", this.fullname);
        fd.append("email", this.email);
        fd.append("phone", this.phone);
        fd.append("category", this.category);
        fd.append("subcategory", this.subcategory);
        fd.append("experiencelevel", this.experiencelevel);
        fd.append("file", this.filedata);
        fd.append("fileType", "pdf");
        fd.append("imageFile", "resumes");
        if (this.$route.params.slug != undefined) {
          fd.append("appliedfor", this.$route.params.slug);
        }
        this.uploadCV(fd);
      }
    },
  },
  computed: {
    ...mapGetters([
      "allCategory",
      "allSubcategory",
      "allUploadCv",
      "allLoadingResume",
    ]),
  },
  updated() {
    if (this.allUploadCv.message != undefined) {
      this.snackbar = true;
      // this.$refs.companyBasicForm.reset();
      this.fullname = "";
      this.email = "";
      this.phone = "";
      this.category = "";
      this.subcategory = "";
      this.experiencelevel = "";
      this.filedata = null;
      this.$refs.companyBasicForm.resetValidation();
    }
  },
  async created() {
    await this.fetchCategory();
    await this.fetchSubCategory();
    await this.f;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/form.scss";
.addmore {
  width: 100%;
}
</style>
