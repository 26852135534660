<template>
  <section>
    <template v-if="!jobContent">
      <v-col md="4" v-for="n in 16" :key="n">
        <v-skeleton-loader
          max-width="420"
          type="image"
          height="200"
        ></v-skeleton-loader>
      </v-col>
    </template>

    <v-col
      md="4"
      v-for="(data, index) in jobContent"
      :key="index"
      :class="{ hidebox: data.jobs.length <= 0 }"
      @mouseenter="hoveredIndex = index"
      @mouseleave="hoveredIndex = null"
    >
      <div class="jobbox">
        <header v-if="data != undefined" class="box-header">
          <router-link :to="'/singeCompany/' + data.baiscInfoCompany.slug">
            <img
              :src="
                'https://app.necojobs.com.np' + data.baiscInfoCompany.imageUrl
              "
              :alt="data.name"
              :title="data.name"
              class="logo"
              v-if="boxtype != 'FEATURED JOBS'"
            />
            <span class="titlecompany">{{
              data.baiscInfoCompany.companyname.slice(0, 12)
            }}</span
            ><span
              class="titlecompany"
              v-if="data.baiscInfoCompany.companyname.length > 12"
              >...</span
            >
          </router-link>
        </header>
        <div class="bodysection">
          <ul
            class="nolist"
            v-if="data != undefined"
            :class="data.jobs.length > 3 && 'overFlowUl'"
          >
            <li v-for="(job, index) in data.jobs" :key="index">
              <span
                >{{ index + 1 }}.
                <router-link :to="'/singlejob/' + job.jobInformation.slug">
                  {{ job.jobInformation.jobPosition.slice(0, 16) }}</router-link
                ></span
              >
              <span
                class="titlecompany"
                v-if="job.jobInformation.jobPosition.length > 16"
                >...</span
              >
            </li>
            <span
              class="displayNone"
              :class="{
                arrowDown: data.jobs.length > 3,
                'arrow-hidden': hoveredIndex === index,
              }"
            >
              <v-icon> mdi-chevron-down </v-icon>
            </span>
          </ul>
        </div>
      </div>
    </v-col>
  </section>
</template>

<script>
export default {
  name: "JobBox",
  data: () => ({
    hoveredIndex: null,
  }),
  props: ["jobContent", "boxtype"],
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.arrow-hidden {
  display: none !important;
}

.displayNone {
  display: none;
}
.arrowDown {
  display: block;
  position: absolute;
  top: 73px;
  left: 50%;

  transform: translateX(-50%);
  .v-icon {
    font-size: 20px;
  }
}
section {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
header {
  display: flex;
  align-content: left;
  justify-content: left;
  padding: 5px;
  a {
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-decoration: none;
  }

  .logo {
    width: 60px;
    height: 60px;
    object-fit: contain;
    background: transparent;
    padding: 5px;
    border: 2px solid #eaeaea;
  }
  .titlecompany {
    color: $dark;
    font-size: 14px;
    text-decoration: none;
    display: block;
    margin-left: 8px;
  }
  border-bottom: 1px solid $menulistcolor;
}
.bodysection {
  height: 95px;
  position: relative;
  span {
    a {
      display: inline-block;
      padding: 2px 0;
      font-size: $normalfont;
      color: $dark;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  ul {
    display: flex;
    flex-flow: column;
    height: 90px;
    width: 100%;
    overflow: hidden;
    position: absolute;
    background: $white;
    width: 100%;
    padding: 10px 5px;
  }
  &:hover {
    .overFlowUl {
      height: auto;
      overflow: visible;
      z-index: 1000;
      box-shadow: 0px 1px 5px 1px rgba(222, 222, 222, 1);
      border-bottom-right-radius: 7px;
      border-bottom-left-radius: 7px;
    }
  }
}
.jobbox {
  // width: 23%;
  box-shadow: 0px 1px 5px 1px rgba(222, 222, 222, 1);
  // margin: 10px;
  border-radius: 7px;
  z-index: 1000 !important;
}
.hidebox {
  display: none;
}
.box-header {
  padding: 6px 10px;
}
</style>
