<template>
  <div>
    <section v-if="allPosts.results && allPosts.results.length > 0">
      <v-container class="container-custom">
        <v-row v-if="allPosts.results">
          <v-col
            md="6"
            v-for="(data, index) in adsData"
            :key="index + 'ad'"
            class="text-center adssection"
          >
            <a :href="data.link" target="_blank">
              <img
                :src="'https://app.necojobs.com.np' + data.imageUrl"
                alt=""
              />
            </a>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section :style="`background-image:url(${bannerUrl})`">
      <v-container>
        <v-row>
          <v-col md="12">
            <h5>
              {{ maintitle }}
            </h5>
            <p>
              {{ description }}
            </p>
            <router-link to="/search/" target="_blank">Find Job</router-link>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "FindJob",
  data: () => ({
    bannerUrl: require("@/assets/necobanner-below.png"),
    maintitle: "Let Employers Find You",
    description:
      "Millions of jobs. Search by What Matters to you and find the one Thats right for you.",
    adsData: [],
  }),
  methods: {
    ...mapActions(["fetchPost"]),
    validate() {},
    nextPage(data) {
      console.log(data);
    },
    prevPage(data) {
      console.log(data);
    },
  },
  computed: {
    ...mapGetters(["allPosts"]),
  },
  async created() {
    await this.fetchPost("ads");
    this.allPosts.results.posts.forEach((ele, index) => {
      if (index < 2 && ele.position == "bottom") {
        this.adsData.push(ele);
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
section {
  min-height: 300px;
  background-size: cover;
  display: flex;
  align-items: center;
}
h5 {
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  color: $white;
  letter-spacing: 1px;
}
p {
  text-align: center;
  color: $white;
  letter-spacing: 1px;
  padding: 15px 0;
}
a {
  color: $white !important;
  text-decoration: none;
  display: block;
  text-align: center;
  padding: 10px 20px;
  background: transparent;
  border: 2px solid $white;
  width: 200px;
  border-radius: 25px;
  margin: 0 auto;
}
a:hover {
  border: 2px solid $tagcolor;
  background-color: $tagcolor;
}

.adssection {
  img {
    width: 100%;
  }
}

@media screen and (max-width: 992px) {
  h1 {
    font-size: 25px;
    padding-bottom: 15px;
  }
  p {
    font-size: 14px;
    padding: 0 5px;
  }
}
.adssection {
  a {
    background: none;
    padding: 0;
    margin: 0 auto;
    width: 100%;
  }
  img {
    @media only screen and (max-width: 991px) {
      width: 100%;
    }
    // width: 100%;
  }
}
</style>
