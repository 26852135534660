<template>
  <section>
    <Banner :homepage="true" title="HomeBanner" connectedTo="page" />
    <v-container class="container-custom padtb" v-if="loading">
      <div v-for="i in 3" :key="i" class="mrb">
        <v-row>
          <v-col md="4" :key="n">
            <v-skeleton-loader
              max-width="420"
              type="image"
              height="30"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
        <v-row>
          <template>
            <v-col md="3" v-for="m in 8" :key="m">
              <v-skeleton-loader
                max-width="420"
                type="image"
                height="200"
              ></v-skeleton-loader>
            </v-col>
          </template>
        </v-row>
      </div>
    </v-container>
    <v-container class="container-custom">
      <v-row>
        <v-col md="9">
          <v-row>
            <v-col
              md="12"
              v-for="(data, index) in allHomeJob.results"
              :key="index"
              class="subscription-section"
            >
              <template v-if="data.home.company.length > 0">
                <div>
                  <h6 class="subscriptiontitle">{{ data.home.type }}</h6>
                </div>
                <v-row class="sectionsjob">
                  <JobBox
                    :jobContent="data.home.company"
                    :boxtype="data.home.type"
                  />
                </v-row>
              </template>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-if="allPosts.results" class="adssection">
          <div class="sortbycat">
            <div class="category-view">
              <h6 class="subscriptiontitle1">Jobs By Category</h6>
              <button>
                <router-link to="/category"> View All </router-link>
              </button>
            </div>
            <ul v-if="!loading">
              <li
                v-for="(data, index) in allHomeCategoriesJob.results"
                :key="index"
              >
                <router-link :to="`/category/${data.slug}`">
                  {{ data.title }} <span>({{ data.jobs }})</span>
                </router-link>
              </li>
            </ul>

            <h6 class="subscriptiontitle1">Courses By Neco</h6>
            <div class="course-container" v-if="!loading">
              <slick
                ref="slick"
                :options="slickOptions"
                v-show="
                  allTrainings.results &&
                  allTrainings.results.list &&
                  allTrainings.results.list.length > 0
                "
              >
                <div
                  v-for="(data, index) in allTrainings.results.list"
                  :key="index"
                  class="mb-5"
                  @click="$router.push(`/course/${data.slug}`)"
                  style="cursor: pointer"
                >
                  <div class="sider-part">
                    <img
                      :src="'https://app.necojobs.com.np' + data.imageUrl"
                      alt=""
                      style="height: 200px; object-fit: cover; width: 100%"
                    />
                    <div class="training-title">
                      <h4>{{ data.trainingTitle }}</h4>
                    </div>
                  </div>
                </div>
              </slick>
            </div>
            <h6 class="subscriptiontitle1 blogs">Blogs</h6>

            <div class="blog-container" v-if="!loading">
              <div
                v-for="(data, index) in blogdata.slice(0, 6)"
                :key="index"
                @click="$router.push(`/singleBlog/${data.slug}`)"
                class="blog-item"
              >
                <template v-if="data.imageUrl">
                  <div class="blog-card">
                    <div class="blog-image">
                      <img
                        :src="'https://app.necojobs.com.np' + data.imageUrl"
                        :alt="data.category"
                      />
                    </div>
                    <div class="blog-info">
                      <h3 class="blog-category">{{ data.title }}</h3>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>

          <!-- <a
            :href="data.link"
            target="_blank"
            v-for="(data, index) in adsData"
            :key="index + 'ad'"
          > -->
          <div class="ads-container">
            <span
              v-for="(data, index) in adsData"
              :key="index + 'ad'"
              @click="
                popupSelected(
                  'https://app.necojobs.com.np' + data.imageUrl,
                  data.link
                )
              "
            >
              <img
                :src="'https://app.necojobs.com.np' + data.imageUrl"
                alt=""
              />
            </span>
          </div>

          <!-- </a> -->
          <div
            class="popUpImageClose"
            v-if="popupStatus"
            @click="popupSelected()"
          ></div>
          <div class="popUpImage" v-if="popupStatus">
            <div>
              <header>
                <v-icon @click="popupSelected()"> mdi-close </v-icon>
              </header>
              <img :src="selectedAd.image" alt="" />
              <a :href="selectedAd.link" target="_blank"> Browse </a>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <FindJob />
  </section>
</template>

<script>
import Banner from "@/components/frontend/Banner";
import FindJob from "@/components/frontend/FindJob";
import JobBox from "@/components/frontend/JobBox";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slick from "vue-slick";

import { mapActions, mapGetters } from "vuex";
export default {
  name: "Home",
  props: ["status", "training"],
  components: {
    Banner,
    FindJob,
    JobBox,
    Slick,
  },

  data: () => ({
    selectedAd: {
      image: "",
      link: "",
      skip: 1,
      limit: 15,
    },

    slickOptions: {
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true,
      infinite: true,
      autoplaySpeed: 4000,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    },
    popupStatus: false,
    img: require("@/assets/noimage.jpeg"),
    snackbar: false,
    subsCriptionType: "Premium Jobs",
    n: 0,
    i: 0,
    adsData: [],
    blogdata: [],
    loading: true,
  }),
  computed: {
    ...mapGetters([
      "allHomeJob",
      "allLogin",
      "allPosts",
      "allHomeCategoriesJob",
      "allTrainings",
      "allPosts",
      "allAssets",
      "allMeta",
    ]),
  },
  methods: {
    reInit() {
      this.$nextTick(() => {
        this.$refs.slick.reSlick();
      });
    },
    next() {
      this.$refs.slick.next();
    },

    prev() {
      this.$refs.slick.prev();
    },
    ...mapActions([
      "getHomeJob",
      "getSingleCompanyUser",
      "fetchPost",
      "getCategoriesHome",
      "getTrainingListingFrontend",
      "fetchPostFront",
      "getAssets",
      "getSingleMetaPage",
    ]),
    popupSelected(image, link) {
      this.popupStatus = !this.popupStatus;
      this.selectedAd.image = image;
      this.selectedAd.link = link;
    },
  },
  metaInfo() {
    return {
      title: this.allMeta && this.allMeta.title,
      meta: [
        {
          name: "description",
          content: this.allMeta && this.allMeta.description,
        },
        {
          name: "keywords",
          content: this.allMeta && this.allMeta.keywords,
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://www.necojobs.com.np",
        },
      ],
    };
  },
  async created() {
    this.getHomeJob();
    await this.fetchPostFront("blog", { limit: this.limit, skip: this.skip });
    this.blogdata = this.allPosts.results.posts;
    if (this.$route.params.slug == "career-tips") {
      this.active = "Career Tips";
      this.datanumber = this.allPosts.results.posts.filter((ele) => {
        return ele.category == this.active;
      }).length;
    }
    if (
      this.allLogin.user != undefined &&
      this.allLogin.user.role == "company"
    ) {
      this.getSingleCompanyUser({ id: this.allLogin.user.userHandle });
    }
    await this.fetchPost("ads");
    this.adsData = this.allPosts.results.posts;
    await this.getAssets({
      title: "Blogs",
      connectedTo: "page",
    });

    await this.getCategoriesHome();
    await this.getTrainingListingFrontend({ limit: 5, skip: 1 });

    await this.getSingleMetaPage("home");
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.adssection {
  img {
    width: 100%;
  }
}

@import "@/assets/scss/variables.scss";
.padtb {
  padding: 30px 0;
}
.mrb {
  margin-bottom: 20px;
}
.subscription-section {
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 0;
  .subscriptiontitle {
    font-size: 20px;
    font-weight: 500;
    padding: 10px 0 15px 0;
    display: inline-block;
    padding-right: 10px;
    letter-spacing: 0.5px;
    text-align: left;
    font-family: "Roboto", sans-serif;
  }
}
.sortbycat {
  padding-bottom: 15px;
  .category-view {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      border: 1px solid $tagcolor;
      padding: 1px 10px;
      background: $white;
      color: $tagcolor;
      border-radius: 5px;
    }
  }
  .courseslide {
    box-shadow: #dedede 0px 1px 5px 1px;
    padding: 6px;
    border-radius: 5px;
    img {
      border-radius: 5px;
      width: 100%;
      object-fit: contain;
    }
  }
  ul {
    box-shadow: rgb(222, 222, 222) 0px 1px 5px 1px;
    padding: 15px 35px;
    border-radius: 10px;
    line-height: 25px;
    li {
      a {
        color: #565656;
        font-size: 15px;

        span {
          color: #565656;
          font-size: 15px;
        }
        span:hover {
          color: $tagcolor;
          text-decoration: underline;
        }
      }
      a:hover {
        color: $tagcolor;
        text-decoration: underline;
      }
    }
  }
  .subscriptiontitle1 {
    font-size: 20px;
    margin: 10px 0 0 0;
    font-weight: 500;
    padding: 10px 0 10px 0;
    display: inline-block;
    padding-right: 10px;
    letter-spacing: 0.5px;
    text-align: left;
    font-family: "Roboto", sans-serif;
  }
}
.popUpImage,
.popUpImageClose {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.175);
  z-index: 9999;
  div {
    width: 700px;
    margin: 0 auto;
    header {
      width: 100%;
      text-align: right;
      padding: 15px 0;
    }
    img {
      width: 100%;
      height: 700px;
      object-fit: contain;
    }
    .v-icon,
    a {
      background: #fff;
      color: #000;
      border-radius: 50%;
      padding: 5px;
    }
    a {
      border-radius: 10px;
      padding: 10px 15px;
      margin-top: 10px;
      display: inline-block;
    }
  }
}
.popUpImageClose {
  z-index: 999;
}
@media screen and (max-width: 600px) {
  .subscription-section {
    padding: 0px 10px;
    overflow: hidden;
  }
  .course-container {
    width: 100% !important;
  }
}
@media screen and (min-width: 768px) {
  .subscription-section {
    width: 100% !important;
    padding: 0px 15px;
    overflow: hidden;
  }
}
.course-container {
  height: auto;

  background: #ffffff;
  overflow: hidden;
  //padding: 15px 13px 0px 11px;
  //box-shadow: rgb(222, 222, 222) 0px 1px 5px 1px;
  width: 271px;
}
.ads-container {
  // padding: 12px 10px 6px 10px;
  background-color: #ffffff;
  //box-shadow: rgb(222, 222, 222) 0px 1px 5px 1px;
  border-radius: 8px;
}
// blog section
.blog-card {
  display: flex;
  align-items: center;
  gap: 10px;
  .blog-category {
    transition: color 0.3s ease;

    &:hover {
      color: $tagcolor;
      text-decoration: underline;
    }
  }
}
.blog-container {
  height: auto;
  overflow-y: auto;
  margin: -10px 0 10px 0;
  //padding: 10px 10px 3px 10px;
  background-color: #ffffff;
  //box-shadow: rgb(222, 222, 222) 0px 1px 5px 1px;
}

.blog-item {
  margin-bottom: 10px;
  cursor: pointer;
}

.blog-image {
  height: 65px;
  min-width: 112px;
  overflow: hidden !important;
}

.blog-image img {
  width: 112px;
  height: 100%;
  object: contain !important;
}
.blog-info {
  color: black;
}

.blog-category {
  margin: 0;
  font-size: 13px;
  font-weight: 400;
}

.sider-part {
  img {
    height: auto !important;
    object-fit: cover !important;
  }
  .training-title {
    h4 {
      font-size: 16px;
      line-height: 19px;
    }
  }
}
.sectionsjob {
  margin-bottom: 5px;
}
.blogs {
  margin-top: -20px !important;
}
</style>
