<template>
  <section
    class="banner"
    :style="`background-image:url(${bannerUrl}); display: flex;align-items: center;padding: 0 30px;`"
  >
    <v-container class="container-custom">
      <v-row>
        <v-col>
          <h1 v-if="homepage">Complete Your Job Solution Simply, Smartly</h1>
          <h5 v-if="homepage">& FASTLY WITH NECO JOBS.</h5>
          <v-form
            ref="searchform"
            v-model="valid"
            lazy-validation
            class="form-section"
          >
            <v-row>
              <v-col md="6" cols="9"
                ><v-text-field
                  v-model="jobTitle"
                  class="whiteback"
                  outlined
                  placeholder="Search jobs by Job Title"
                ></v-text-field
              ></v-col>
              <v-col md="2" cols="2">
                <v-btn class="btn btn-default" @click="searchJob"
                  ><v-icon> mdi-magnify </v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <v-col md="4" class="flexbox-center" v-if="!homepage"></v-col>
        <v-col md="4" class="flexbox-center" v-if="homepage">
          <template v-if="allLogin.user != undefined">
            <ul
              class="createupload nolist"
              v-if="homepage && allLogin.user.role == 'user'"
            >
              <li
                @click="openResume"
                v-if="allLogin.user && allResumeStatus != 9"
              >
                Create Resume
              </li>
              <li
                @click="openResume"
                v-if="allLogin.user && allResumeStatus == 9"
              >
                Update Resume
              </li>
              <li @click="openResume" v-if="allLogin.user == undefined">
                Create Resume
              </li>
              <li @click="openCV">Upload Resume</li>
            </ul>
            <ul
              class="createupload nolist"
              v-if="homepage && allLogin.user.role == 'company'"
            >
              <li @click="addjob" v-if="allLogin.user">Add Job</li>
            </ul>
            <ul
              class="createupload nolist"
              v-if="homepage && allLogin.user.role == 'company'"
            >
              <li @click="addCompanyDetail" v-if="allLogin.user">
                Update Company
              </li>
            </ul>
          </template>

          <ul class="createupload nolist" v-if="allLogin.token == undefined">
            <li @click="openResume">Create Resume</li>
            <li @click="openCV">Upload Resume</li>
          </ul>
        </v-col>
      </v-row>
    </v-container>

    <div class="upload-cv" :class="{ leftSlide: slideUpload }">
      <ul class="topaction nolist">
        <li><h3>Upload Your CV</h3></li>
        <li><v-icon @click="closeCV"> mdi-close-circle-outline </v-icon></li>
      </ul>
      <AddCv />
    </div>
    <v-snackbar v-model="snackbar">
      <span> Please type job title keyword to search </span>
    </v-snackbar>
  </section>
</template>

<script>
import AddCv from "@/components/AddCv";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Banner",
  data: () => ({
    bannerUrl: require("@/assets/placeholder.jpg"),
    snackbar: false,
    valid: true,
    location: "",
    jobTitle: "",
    slideUpload: false,
  }),
  components: { AddCv },
  props: ["homepage", "connectedTo", "title"],
  methods: {
    ...mapActions(["getAssets"]),
    searchJob() {
      // if (this.jobTitle == "") {
      //   this.snackbar = true;
      // } else {
      this.$router.push(`/search/${this.jobTitle}`);
      // }
    },
    closeCV() {
      this.slideUpload = false;
    },
    openCV() {
      this.slideUpload = true;
    },
    openResume() {
      if (this.allLogin.user != undefined) {
        this.$router.push("/addresume");
      } else {
        this.$router.push("/login");
      }
    },
    addCompanyDetail() {
      if (this.allLogin.user != undefined) {
        this.$router.push(`/addcompany/${this.allLogin.user.userHandle}`);
      } else {
        this.$router.push("/login");
      }
    },
    addjob() {
      if (this.allLogin.user != undefined) {
        this.$router.push(`/addjob/${this.allLogin.user.userHandle}`);
      } else {
        this.$router.push("/login");
      }
    },
  },
  computed: {
    ...mapGetters(["allUploadCv", "allLogin", "allResumeStatus", "allAssets"]),
  },
  async created() {
    if (this.$route.params.slug != undefined) {
      this.jobTitle = this.$route.params.slug;
    }
    await this.getAssets({
      title: this.title,
      connectedTo: this.connectedTo,
    });
    this.bannerUrl = this.allAssets[0].image;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.banner {
  min-height: 230px;
  background-size: cover;
  display: flex;
  align-items: center;
}
.whiteback {
  background: $white;
  min-height: 40px;
  border-radius: 5px;
}
.btn-default {
  min-height: 55px;
  border-radius: 5px;
}
h1 {
  font-size: 26px;
  letter-spacing: 1.2px;
  color: $white;
}
h5 {
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 1.8px;
  color: $tagcolor;
}
.flexbox-center {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .createupload {
    display: flex;
    flex-flow: column;
    margin-bottom: 0;
    li {
      background: $tagcolor;
      text-align: center;
      color: $white;
      padding: 8px 20px;
      margin-bottom: 10px;
      cursor: pointer;
      border-radius: 5px;
      border: 2px solid $tagcolor;
      transition: 0.5s;
      &:last-of-type {
        margin-bottom: 0;
      }
      &:hover {
        color: $white;
        border: 2px solid $white;
        background: transparent;
      }
    }
  }
}
.upload-cv {
  overflow: scroll;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 500px;
  background: $white;
  z-index: 9999;
  right: -1000px;
  padding: 20px;
  transition: 0.3s all ease-in-out;
}
.topaction {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  list-style-type: none !important;

  li {
    margin: 0;
    .v-icon {
      cursor: pointer;
    }
  }
}
.leftSlide {
  box-shadow: 0px 1px 14px 1px rgba(222, 222, 222, 1);
  right: 0;
  transition: 0.3s all ease-in-out;
}
@media screen and (max-width: 600px) {
  .banner {
    height: auto !important;
    width: 100%;
    padding: 0px 5px !important;
    background-position-x: center;
    background-size: auto;
    h4 {
      font-size: 20px;
      color: #666666;
    }
    h5 {
      font-size: 30px;
    }
  }
  .flexbox-center {
    display: flex;
    justify-content: center !important;
    .createupload {
      font-size: 13px;
      margin: 0 20px !important;
    }
  }
  .v-application ul {
    padding: 0;
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
}

/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
  .topsection {
    display: none;
  }
  .banner {
    padding: 10px;
    height: 350px;
    h2 {
      font-size: 18px;
    }
    h1 {
      font-size: 22px;
    }
    .form-section {
      padding: 10px 0;
    }
  }

  .flexbox-center {
    justify-content: left;
    padding: 0;
    .createupload {
      display: flex;
      // flex-flow: inherit;
      width: 100%;
      margin-bottom: 10px !important;
      li {
        margin-bottom: 0;
        padding: 5px 30px;
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .upload-cv {
    width: 100%;
    .topaction {
      background: #4782da;
      padding: 15px;
      margin: 0;
      color: #fff;
      .notranslate {
        color: #fff;
      }
    }
  }
}
</style>
